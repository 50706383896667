angular.module('MyHippoCommons.Directives').directive('singletonModal', function () {
    return {
        scope: {
            modalData: '<',
            totalNumberModals: '<',
            currentModal: '<',
            closeModal: '&',
            goBackTo: '&',
            shouldDisplayModal: '<'
        },
        restrict: 'E',
        transclude: true,
        link: function(scope) {
            scope.close = function() {
                scope.closeModal()("Directive Args");
            };
            scope.moveTo = function (index){
                scope.goBackTo()(index,false);
            };
        },
        templateUrl: '/modals/animated-multi/singleton/singleton.html',

        controller: function ($scope) {
            $scope.$watch('shouldDisplayModal', function() {
                if($scope.modalData && $scope.modalData.overRideLinks){
                    for (const a of document.querySelectorAll("a")) {
                        for(const override of $scope.modalData.overRideLinks ){
                            if (a.textContent.includes(override.text)) {
                                a.setAttribute('href', override.link);
                            }
                        }
                    }

                }
            });
        },
    };
});
